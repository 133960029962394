.hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;

    .particle-container {
        margin-top: 3rem;
        display: flex;
        justify-content: center;
    }

    #tsparticles {
        position: absolute;
        width: 90vw;
        height: 60vw;
    }

    h1 {
        font-weight: 900;
        margin-top: 5rem;
        font-size: 4rem;
    }

    p {
        font-weight: 900;
    }

    .logo {
        height: auto;
        width: 60%;
        margin: 0 auto;
        z-index: 10;
        position: relative;
    }
}

@media screen and (min-width: 992px) {
    .hero-container {
        .particle-container {
            margin-top: 0;
        }
    }
}
