/* stylelint-disable property-no-vendor-prefix */
.header {
    height: 3rem;
    padding: 2rem 3rem;
    padding-left: 1rem;
    display: flex;
    align-items: center;
    font-family: Nunito, sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    max-width: 100%;
    box-shadow: 0 -6px 10px 5px rgb(0 0 0 / 50%);

    .logo-container {
        display: flex;
        align-items: center;

        .logo {
            height: 3rem;
            width: 3rem;
            margin-right: 1rem;
        }
    }

    .mobile-nav-button {
        position: absolute;
        right: 2rem;
        top: 1rem;
        border: none !important;
        z-index: 15;

        &.mobile-nav-button-open {
            color: #fff;
            position: fixed;
        }
    }

    .mobile-nav-bar {
        position: fixed;
        top: 0;
        right: -100%;
        height: 100vh;
        width: 100vw;
        background-color: rgb(0 0 0);
        padding: 1rem 3rem;
        display: flex;
        flex-direction: column;
        transition: right 0.5s ease-out;
        z-index: 11;

        &.mobile-nav-bar-open {
            right: 0;
        }

        .mobile-menu-close-icon {
            margin-left: auto;
            position: fixed;
        }

        .mobile-header-links {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            margin-left: auto;
            padding-top: 3rem;

            a {
                text-decoration: none;
                color: #fff;
                margin: 2rem 0;
                padding: 0.5rem;
                font-size: 1.5rem;
                border-radius: 10px;
                transition: background-color 0.5s ease-out, color 0.5s ease-out;

                &:hover {
                    background-color: #fff;
                    color: #000;
                }
            }
        }
    }

    .header-links {
        display: none;
    }
}

.animated-icon1 {
    width: 30px;
    height: 20px;
    position: relative;
    margin: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

.animated-icon1 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    background: #000;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &.open {
        background: #fff;
    }
}

.animated-icon1 span:nth-child(1) {
    top: 0;
}

.animated-icon1 span:nth-child(2) {
    top: 10px;
}

.animated-icon1 span:nth-child(3) {
    top: 20px;
}

.animated-icon1.open span:nth-child(1) {
    top: 11px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.animated-icon1.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.animated-icon1.open span:nth-child(3) {
    top: 11px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

@media screen and (min-width: 992px) {
    .mobile-menu-icon {
        display: none;
    }

    .nav-bar {
        display: none;
    }

    .mobile-nav-button {
        display: none;
    }

    .header {
        border-bottom: 1px rgb(0 0 0 / 36.3%) solid;

        .header-links {
            display: flex;
            margin-left: auto;
            justify-content: space-around;
            align-items: center;

            a {
                text-decoration: none;
                padding: 0 2rem;
                color: rgb(0 0 0);
                transition: all 0.3s ease-in-out;

                &:hover {
                    color: rgb(44 44 44);
                    transform: scale(1.1);
                }

                span {
                    font-weight: 900;
                }
            }
        }
    }
}
