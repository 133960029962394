.employee-profile {
    min-width: 30vw;

    h3 {
        text-decoration: underline;
    }

    .headshot {
        border-radius: 50%;
        height: 10rem;
        width: 10rem;
        object-fit: cover;
        object-position: 50% 40%;
        margin: 2rem 0;
        border: solid 2px #000;
    }

    p {
        max-width: 30rem;
        text-align: center;
        margin: 3rem auto 0;
    }
}
