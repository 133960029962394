.who-we-are {
    display: flex;
    flex-direction: column;
    text-align: center;

    .employees-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5rem;
    }
}

@media screen and (min-width: 992px) {
    .who-we-are {
        .employees-container {
            flex-direction: row;
        }
    }
}
