.what-we-do {
    padding: 7rem 3rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .what-we-do-item {
        padding: 5rem 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;

        svg {
            height: 200px;
            width: 200px;
            margin: 0 auto 3rem;
        }

        h3 {
            padding-bottom: 1.5rem;
            font-size: 2rem;
        }
    }
}

@media screen and (min-width: 992px) {
    .what-we-do-items-container {
        display: flex;
        justify-content: space-around;
        flex-direction: row;

        .what-we-do-item {
            max-width: 20rem;
        }
    }
}
