@import "~bootstrap/scss/bootstrap";

* {
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Montserrat, sans-serif;
}

p {
  line-height: 1.4em;
  font-size: 1.2rem;
}

img {
  height: 50rem;
  width: 100%;
}

h1,
h2 {
  font-size: 3rem;
}

h3 {
  font-weight: 700;
}

section {
  padding: 7rem 3rem;
}
